import React from "react";
import { Script } from "gatsby";
const Reviews = () => {
  return (
    <div
      style={{
        padding: "20px 10px",
      }}
    >
      <Script src="https://apps.elfsight.com/p/platform.js" defer></Script>
      <div className="elfsight-app-b0a13283-7877-472e-901d-2598654a7ed7"></div>
    </div>
  );
};

export default Reviews;
